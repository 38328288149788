<template>
  <svg width="254" height="50" viewBox="0 0 254 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1218_22774)">
      <path d="M130.134 13.9615C130.134 12.8914 130.394 11.9109 130.913 11.0189C131.431 10.1269 132.136 9.41436 133.025 8.87871C133.915 8.3443 134.909 8.07642 136.006 8.07642H137.695C138.259 8.07642 138.734 8.27056 139.12 8.65635C139.505 9.04215 139.697 9.53352 139.697 10.1269V11.331C139.697 11.8961 139.505 12.3716 139.12 12.7574C138.734 13.1444 138.259 13.3373 137.695 13.3373H136.006C135.827 13.3373 135.68 13.4037 135.561 13.5376C135.442 13.6715 135.383 13.8129 135.383 13.9615V44.115C135.383 44.2648 135.442 44.4049 135.561 44.5388C135.68 44.6727 135.827 44.7391 136.006 44.7391H137.695C138.259 44.7391 138.734 44.9258 139.12 45.2968C139.505 45.6691 139.697 46.1372 139.697 46.7011V47.9494C139.697 48.5145 139.505 48.9973 139.12 49.3979C138.734 49.7996 138.259 49.9999 137.695 49.9999H136.006C134.909 49.9999 133.915 49.732 133.025 49.1976C132.136 48.662 131.431 47.9494 130.913 47.0574C130.394 46.1654 130.134 45.185 130.134 44.1148V13.9615Z" fill="#067A82"/>
      <path d="M253.5 44.115C253.5 45.185 253.24 46.1655 252.721 47.0575C252.203 47.9495 251.498 48.6621 250.609 49.1977C249.719 49.7321 248.725 50 247.628 50H245.939C245.375 50 244.9 49.7997 244.514 49.398C244.129 48.9975 243.937 48.5146 243.937 47.9495V46.7012C243.937 46.1373 244.129 45.6692 244.514 45.2969C244.9 44.9259 245.375 44.7392 245.939 44.7392H247.628C247.807 44.7392 247.954 44.6728 248.073 44.5389C248.192 44.405 248.251 44.2649 248.251 44.1151V13.9615C248.251 13.8129 248.192 13.6715 248.073 13.5376C247.954 13.4037 247.807 13.3373 247.628 13.3373H245.939C245.375 13.3373 244.9 13.1444 244.514 12.7574C244.129 12.3716 243.937 11.8962 243.937 11.331V10.1269C243.937 9.53352 244.129 9.04205 244.514 8.65635C244.9 8.27056 245.375 8.07642 245.939 8.07642H247.628C248.725 8.07642 249.719 8.3443 250.609 8.87871C251.498 9.41436 252.203 10.1269 252.721 11.0189C253.24 11.9109 253.5 12.8913 253.5 13.9615V44.115Z" fill="#067A82"/>
      <path d="M64.9111 45.1758C61.768 45.1758 58.9877 44.4853 56.5716 43.1032C54.1542 41.721 52.2626 39.8204 50.8995 37.3975C49.5351 34.9748 48.8535 32.2031 48.8535 29.0824C48.8535 25.9028 49.5351 23.1016 50.8995 20.68C52.2626 18.2573 54.1395 16.3627 56.5263 14.9953C58.913 13.6292 61.6639 12.9448 64.7776 12.9448C67.9207 12.9448 70.6863 13.6353 73.073 15.0174C75.4598 16.3995 77.3366 18.3027 78.701 20.7242C80.0642 23.1469 80.747 25.9335 80.747 29.0823C80.747 32.2029 80.0716 34.9746 78.7231 37.3974C77.3734 39.8202 75.5052 41.7208 73.1184 43.1031C70.7304 44.4852 67.9954 45.1758 64.9111 45.1758ZM64.9111 39.6041C66.8088 39.6041 68.4772 39.1508 69.9152 38.2441C71.3531 37.3374 72.4797 36.0977 73.2961 34.5215C74.1113 32.9465 74.5196 31.1342 74.5196 29.0825C74.5196 27.0025 74.104 25.1756 73.2741 23.5993C72.4429 22.0243 71.3017 20.7845 69.849 19.8767C68.3952 18.9712 66.7046 18.5179 64.7777 18.5179C62.9094 18.5179 61.2411 18.9712 59.7736 19.8767C58.3063 20.7846 57.1564 22.0243 56.3264 23.5993C55.4953 25.1756 55.081 27.0025 55.081 29.0825C55.081 31.1342 55.4953 32.9465 56.3264 34.5215C57.1563 36.0977 58.3123 37.3374 59.7957 38.2441C61.279 39.1508 62.9828 39.6041 64.9111 39.6041Z" fill="#067A82"/>
      <path d="M85.6835 43.55V14.5546C85.6835 13.9363 86.1836 13.435 86.8005 13.435H91.7171C92.0882 13.435 92.435 13.6197 92.6427 13.9279L101.384 26.898L110.127 13.9279C110.335 13.6197 110.682 13.435 111.053 13.435H115.925C116.542 13.435 117.042 13.9363 117.042 14.5546V43.55C117.042 44.1528 116.555 44.6414 115.954 44.6414H112.082C111.48 44.6414 110.993 44.1528 110.993 43.55V22.9751L101.384 37.1518L91.7772 23.0205V43.55C91.7772 44.1528 91.2897 44.6414 90.6883 44.6414H86.7723C86.1708 44.6414 85.6835 44.1528 85.6835 43.55Z" fill="#067A82"/>
      <path class="minor" d="M146.98 44.6281V13.4352H151.428V25.6003C157.216 16.9162 167.194 13.8289 171.489 13.4352H173.891V44.6282H169.443V18.2058C167.93 18.5622 158.477 20.6832 151.428 32.4914V44.6283L146.98 44.6281Z" fill="#D32020"/>
      <path class="minor" d="M209.253 44.6281H203.249L191.505 32.8139L184.744 37.7185V44.6281H180.297V20.109H184.744V32.5915L202.225 20.109H209.031L194.93 30.3174L209.253 44.6281Z" fill="#D32020"/>
      <path class="minor" d="M236.654 44.6281H220.862C218.579 44.6281 216.578 44.0789 214.858 42.9781C213.138 41.8797 211.803 40.3931 210.854 38.5208C209.905 36.6484 209.431 34.5683 209.431 32.2795C209.431 29.9907 209.905 27.9254 210.854 26.0825C211.803 24.2408 213.138 22.769 214.858 21.6694C216.578 20.5698 218.578 20.0194 220.862 20.0194H236.654V24.5222H220.862C219.439 24.5222 218.208 24.8723 217.171 25.5702C216.133 26.2693 215.332 27.1968 214.769 28.3566C214.206 29.5151 213.924 30.8236 213.924 32.2795C213.924 33.7354 214.206 35.0586 214.769 36.2467C215.332 37.436 216.133 38.3795 217.171 39.0774C218.208 39.7765 219.439 40.1254 220.862 40.1254H236.654V44.6281Z" fill="#D32020"/>
      <path d="M45.7562 38.3572L27.2942 2.33842C26.5585 0.90102 25.0993 0.0047706 23.4865 0H23.4749C21.8662 0 20.4065 0.887642 19.6637 2.31872L0.987967 38.3407C0.290727 39.6861 0.343294 41.2629 1.1289 42.5588C1.9141 43.8546 3.28644 44.6282 4.79868 44.6282H41.9373C43.446 44.6282 44.8168 43.8571 45.6025 42.566C46.3897 41.2747 46.4464 39.7011 45.7562 38.3572ZM7.74244 38.5472L23.4567 8.23685L38.9937 38.5474L7.74244 38.5472Z" fill="#067A82"/>
    </g>
    <defs>
      <clipPath id="clip0_1218_22774">
        <rect width="253" height="50" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
    </svg>
</template>