<template>
  <div 
    :class="dropdownClasslist"
    class="sm-profile-dropdown"
  >
    <div class="sm-profile-dropdown__title">
      <div class="sm-profile-dropdown__user-info">
        <div class="sm-profile-dropdown__icon-container">
          <sm-icon
            name="User"
            :size="24"
          />
        </div>
        <span class="sm-profile-dropdown__username">{{ userName }}</span>
      </div>
    </div>

    <ul class="sm-profile-dropdown__menu">
      <slot></slot>

      <sm-profile-dropdown-menu-item
        title="Выйти"
        icon="Exit"
        @click="handleLogout"
        @keyup.enter="handleLogout"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
// Vue
import { computed, withDefaults, defineProps, defineEmits } from 'vue';

// Components
import SmProfileDropdownMenuItem from '@/components/layouts/header/SmProfileDropdownMenuItem.vue';

// Types
import { IDropdownMenuProps } from '@/types/components/headerMenu';
import { Class } from '@/types/common';

// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';

const props = withDefaults(defineProps<IDropdownMenuProps>(), {
  userName: '',
  theme: 'light'
});

const { userName, theme } = props;

// Emits
const emits = defineEmits<{
  (e: 'logout'): void;
}>();

// Computed
const dropdownClasslist = computed((): Class => ([
  `sm-profile-dropdown--${theme}`
]));

// Methods
const handleLogout = (): void => emits('logout');

</script>

<style lang="scss">
.sm-profile-dropdown {
  position: absolute;
  right: 0;

  width: 248px;
  max-width: 248px;
  
  background: var(--Background);
  border-radius: 8px;
}

.sm-profile-dropdown--light {
  box-shadow: 0 4px 8px 0 rgba(60, 80, 119, 0.08), 0 8px 16px 0 rgba(21, 45, 91, 0.11);
}

.sm-profile-dropdown--dark {
  box-shadow: 0 4px 8px 0 rgba(2, 8, 24, 0.65), 0 8px 21px 0 rgba(11, 42, 47, 0.19);
}

.sm-profile-dropdown__title {
  margin-bottom: var(--margin);
}

.sm-profile-dropdown__user-info {
  display: flex;
  gap: var(--gap-s);
  padding: var(--padding-s) var(--padding);
}

.sm-profile-dropdown__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  height: 32px;
  
  background-color: var(--SecContainer);
  border-radius: 50%;
}

.sm-profile-dropdown__menu {
  padding: 0;

  border-top: 1px solid var(--BorderMinor);
}

</style>
