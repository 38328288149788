<template>
  <div 
    class="sm-theme-switcher"
    @click="toggleTheme"
  >
    <div class="sm-theme-switcher__slider">
      <!-- Анимация для иконок, при смене темы срабатывает анимация -->
      <transition
        v-if="isAnimating"
        name="icon-fade"
        mode="out-in"
        class="sm-theme-switcher__slider-container"
      >
        <div
          v-if="isLightTheme"
          key="light"
          class="sm-theme-switcher__icon sm-theme-switcher__icon--light"
        ></div>
        <div
          v-else
          key="dark"
          class="sm-theme-switcher__icon sm-theme-switcher__icon--dark"
        ></div>
      </transition>
      <div v-else :class="iconWrapperClassList"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRefs } from 'vue';

// Props
const props = withDefaults(defineProps<{ appTheme: string }>(), {
  appTheme: 'light'
});

const { appTheme } = toRefs(props);

// Emits
const emits = defineEmits<{
  (e: 'toggleTheme'): void;
}>();

// State
// Состояние для анимации переключения темы
const isAnimating = ref<boolean>(false); 

// Computed
// Состояние текущей темы
const isLightTheme = computed<boolean>(() => appTheme.value === 'light');

// Вычисление URL изображения в зависимости от текущей темы
const iconWrapperDefaultImage = computed<string>(() => {
  const url = isLightTheme.value ? require('@/assets/icons/moon.svg') : require('@/assets/icons/sun.svg');
  return `url(${url})`;
});

const iconWrapperClassList = computed(() => [
  'sm-theme-toggle__container',
  'sm-theme-toggle__icon-wrapper',
  isLightTheme.value ? 'sm-theme-switcher__icon--moon-hover' : 'sm-theme-switcher__icon--sun-hover',
]);

// Methods
// Метод для переключения темы
const toggleTheme = (): void => {
  isAnimating.value = true;
  emits('toggleTheme');
  
  setTimeout(() => {
    isAnimating.value = false;
  }, 1000)
};
</script>

<style lang="scss" scoped>
.sm-theme-switcher {
  position: fixed;
  bottom: var(--padding);
  right: var(--padding);
  cursor: pointer;
  z-index: 1000;
}

.sm-theme-switcher__slider {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.sm-theme-switcher__slider-container, 
.sm-theme-toggle__container {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: var(--Body);
}

.sm-theme-switcher__icon, 
.sm-theme-toggle__icon-wrapper {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-position .2s linear, background-image .2s linear; 
  background-image: v-bind(iconWrapperDefaultImage);
  background-color: var(--Body);
}


.sm-theme-switcher__icon.sm-theme-switcher__icon--dark {
  animation: light 1s forwards;
}

.sm-theme-switcher__icon.sm-theme-switcher__icon--light {
  animation: dark 1s forwards;
}

.sm-theme-switcher__icon--moon-hover:hover {
  background-image: url("@/assets/icons/halfOfMoon.svg");
  background-position: center 25px;
}

.sm-theme-switcher__icon--sun-hover:hover {
  background-image: url("@/assets/icons/halfOfSun.svg");
  background-position: center 30px;
}

@keyframes dark {
  0% {
    background-image: url("@/assets/icons/sun.svg");
    background-position: center;
    background-color: var(--Background);
  }
  25% {
    background-image: url("@/assets/icons/halfOfSun.svg");
    background-position: center 40px;
  }
  50% {
    background-image: none;
    background-color: #7171A7;
  }
  75% {
    background-image: url("@/assets/icons/halfOfMoon.svg");
    background-position: center 40px;
  }
  100% {
    background-image: url("@/assets/icons/moon.svg");
    background-position: center;
  }
}

@keyframes light {
  0% {
    background-image: url("@/assets/icons/moon.svg");
    background-position: center;
    background-color: var(--Background);
  }
  25% {
    background-image: url("@/assets/icons/halfOfMoon.svg");
    background-position: center 40px;
  }
  50% {
    background-image: none;
    background-color: #7171A7;
  }
  75% {
    background-image: url("@/assets/icons/halfOfSun.svg");
    background-position: center 40px;
  }
  100% {
    background-image: url("@/assets/icons/sun.svg");
    background-position: center;
  }
}
</style>
