<template>
  <div
    class="sm-tooltip"
    @mouseover="toggleTooltip(true)"
    @mouseleave="toggleTooltip(false)"
  >
    <slot></slot>
    <div
      v-if="visible"
      :class="tooltipClassList"
      :style="tooltipStyle"
    >
      <div 
        class="sm-tooltip__text"
        :class="textClassList"
        v-html="text"
      >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';

// Types
import { Class } from '@/types/common';
import { ITooltipProps } from '@/types/components/tooltip';

// Props
const props = withDefaults(
  defineProps<ITooltipProps>(),
  {
    text: '',
    isError: false,
    position: 'top',
    align: 'middle',
    offset: 10,
  }
);

// Data
const visible = ref(false);

// Computed
const tooltipClassList = computed((): Class => [
  'sm-tooltip__content',
  `sm-tooltip__content--${props.position}`,
  `sm-tooltip__content--${props.align}`,
]);

const tooltipStyle = computed(() => ({ 
  padding: `${props.offset}px`,
  width: `${props.offset*2 + 260}px`
}));

const textClassList = computed((): Class =>  ({
  'sm-tooltip__text--error': props.isError
}));

// Methods
const toggleTooltip = (state: boolean) => {
  if (!props.text) return;
  visible.value = state;
};
</script>


<style lang='scss'>
.sm-tooltip {
  position: relative;
  display: flex;
}

.sm-tooltip__content {
  position: absolute;
  z-index: 1000;
}

.sm-tooltip__content--top {
  bottom: 100%;
}

.sm-tooltip__content--bottom {
  top: 100%;
}

.sm-tooltip__content--left {
  right: 100%;
}

.sm-tooltip__content--right {
  left: 100%;
}

.sm-tooltip__text {
  @include micro;

  position: relative;
  padding: 5px 10px;
  background-color: var(--Surface);
  color: var(--OnSurfaceTitle);
  border-radius: 4px;
  max-width: 240px;
  width: fit-content;
  white-space: pre-wrap;
  box-shadow: 0px 4px 8px 0px rgba(var(--DropdownShadow));
}

.sm-tooltip__text::after {
  content: '';
  position: absolute;
  border: 10px solid transparent;
}

.sm-tooltip__text--error {
  color: var(--Error);
}

.sm-tooltip__content--top .sm-tooltip__text::after {
  top: 100%;
  border-top: 10px solid var(--Surface);
}

.sm-tooltip__content--bottom .sm-tooltip__text::after {
  bottom: 100%;
  border-bottom: 10px solid var(--Surface);
}

.sm-tooltip__content--left .sm-tooltip__text::after {
  left: 100%;
  border-left: 10px solid var(--Surface);
}

.sm-tooltip__content--right .sm-tooltip__text::after {
  right: 100%;
  border-right: 10px solid var(--Surface);
}

.sm-tooltip__content--top.sm-tooltip__content--start,
.sm-tooltip__content--bottom.sm-tooltip__content--start {
  right: 75%;
}

.sm-tooltip__content--top.sm-tooltip__content--start .sm-tooltip__text::after
.sm-tooltip__content--bottom.sm-tooltip__content--start .sm-tooltip__text::after {
  right: var(--gap-s);
}

.sm-tooltip__content--top.sm-tooltip__content--middle,
.sm-tooltip__content--bottom.sm-tooltip__content--middle,
.sm-tooltip__content--top.sm-tooltip__content--middle .sm-tooltip__text::after,
.sm-tooltip__content--bottom.sm-tooltip__content--middle .sm-tooltip__text::after {
  left: 50%;
  transform: translateX(-50%);
}

.sm-tooltip__content--top.sm-tooltip__content--end,
.sm-tooltip__content--bottom.sm-tooltip__content--end {
  left: 75%;
}

.sm-tooltip__content--top.sm-tooltip__content--end .sm-tooltip__text::after,
.sm-tooltip__content--bottom.sm-tooltip__content--end .sm-tooltip__text::after {
  left: var(--gap-s);
}

.sm-tooltip__content--left.sm-tooltip__content--start,
.sm-tooltip__content--right.sm-tooltip__content--start {
top: 0;
}

.sm-tooltip__content--left.sm-tooltip__content--start .sm-tooltip__text::after,
.sm-tooltip__content--right.sm-tooltip__content--start .sm-tooltip__text::after {
  top: var(--gap-s);
}

.sm-tooltip__content--left.sm-tooltip__content--middle,
.sm-tooltip__content--right.sm-tooltip__content--middle,
.sm-tooltip__content--left.sm-tooltip__content--middle .sm-tooltip__text::after,
.sm-tooltip__content--right.sm-tooltip__content--middle .sm-tooltip__text::after {
  top: 50%;
  transform: translateY(-50%);
}

.sm-tooltip__content--left.sm-tooltip__content--end,
.sm-tooltip__content--right.sm-tooltip__content--end {
  bottom: 0;
}

.sm-tooltip__content--left.sm-tooltip__content--end .sm-tooltip__text::after,
.sm-tooltip__content--right.sm-tooltip__content--end .sm-tooltip__text::after {
  bottom: var(--gap-s);
}
</style>